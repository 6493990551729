import styled from "styled-components";
import MarkedToHTML from "../../../Components/MarkedToHTML";
import Title from "../../../Components/Title";
import useViewWidth from "../../../Hooks/useViewWidth";

const ArticleEtreEtMission = styled.article`
    margin:10vh;
    margin-left:40%;
    @media (max-width: 778px) {
        margin:5vh;
    }
    position: relative;
    `;

const Red = styled.u`
    text-decoration: none;
    color: #D12834`;

const RedBox = styled.div`
    margin-top:10vh;
    padding: 5vh;
    background: #dd1e1ea1;
    background-blend-mode: overlay;
    padding-left:50%;
    @media (max-width: 1007px) {
        padding: 5vh;
    }
`;


const Paragraph = styled.p`
    margin: 3vh 0 5vh 0;
    font-family: Montserrat, sans-serif;
    line-height: 23px;
`;

const TitleEtreEtMission = styled(Title)`
    text-align:left;
    `;


    

export default function ArticleEtreEtMissionComponent({content}){
    const vw = useViewWidth();
    const alignTitle = vw > 778 ? "right" : "left";
    const alignSubTitle = vw > 778 ? "right" : "center";

    return(
        <ArticleEtreEtMission>
            <TitleEtreEtMission h='h1' mb='1vh' align={alignTitle} size='8vmin'>Notre raison d’être</TitleEtreEtMission>
            <Title h='h2' mt='6vh' size='5vmin' align={alignSubTitle}><Red><MarkedToHTML>{content.missionQuote}</MarkedToHTML></Red></Title>
            <RedBox>
                <Title h='h3' pt='2vh' size='22px' bt='2px solid black'>Notre vision :</Title>
                <Paragraph> 
                    <MarkedToHTML>{content.missionText}</MarkedToHTML>
                </Paragraph>
                <Title h='h3' pt='1vh' size='22px' bt='2px solid black'>Notre action : S’inspirer, échanger, agir ensemble</Title>
                <Paragraph> 
                    <MarkedToHTML>{content.purposeText}</MarkedToHTML>
                </Paragraph>
            </RedBox>
        </ArticleEtreEtMission>  
    )
}