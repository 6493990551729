import styled from 'styled-components';
import ButtonDefault from '../../../Components/buttonDefault';

const DivMembers = styled.div`
    width: 10vw;
    padding-left: 1rem;
    margin-top: 35vh;

    @media (max-width: 450px) {
        width: 35vw;
        margin-top: 20vh;
        
    }

    @media (min-width: 451px) and (max-width: 530px) {
        width: 30vw;
        margin-top: 25vh;
    }

    @media (min-width: 531px) and (max-width: 620px) {
        width: 30vw;
        margin-top: 25vh;
    }

    @media (min-width: 621px) and (max-width: 720px) {
        width: 30vw;
        margin-top: 31vh;
    }

    @media (min-width: 721px) and (max-width: 1000px) {
        width: 30vw;
        margin-top: 50vh;
    }

    @media (min-width: 1001px) and (max-width: 1210px) {
        width: 15vw;
    }
`

const DivMembersText = styled.div`
    font-family: Montserrat,sans-serif;
    padding-bottom: 4vh;
    padding-right:5px;

    @media (max-width: 450px) {
        font-size: 1.4vh;
    }

    @media (min-width: 451px) and (max-width: 778px) {
        font-size: 1.6vh;
    }
`


export default function citation({text}){

    return (
        <DivMembers>
            <DivMembersText>{text}</DivMembersText>
            <ButtonDefault link="/club" text="MEMBRES" />
        </DivMembers>
    )
}