import styled from "styled-components";
import Icon from "./Icon";
import WrapperFlex from "./WrapperFlex";

const Value = styled.p`
    margin-left: 0.5vh;
    font-size: 4vh;
    color: #D12834; 
`;

const Text = styled.p`
    text-align: center;
    font-size: 1.3vh;
    letter-spacing: 0.30vh;
    text-transform:uppercase`;

export default function Number({icon,text,number}){
    return(
        <WrapperFlex  direction='column' position='center' mt='2vh'>
            <WrapperFlex direction='row'>
                    <Icon size='4vh' src={icon}/>
                <Value>{number}</Value>
            </WrapperFlex>
            <Text>{text}</Text>
        </WrapperFlex>
    )
}