import styled from "styled-components";
import ArticleTitles from '../Pages/Action/components/article/ArticleTitles';
import Article from '../Pages/Action/components/article/Article';

// //--------- CSS DESCRIPTION ARTICLE --------//

const Component = styled.div`
  display: ${$props => $props.show ? "none" : "flex"};
  flex-direction: row;
  width: 90vw;
  margin: 0 auto;
  min-height: 100vh; 
  background-color: white;
  padding: 10vh 0 10vh 0;

`;

export default function EachArticle({show, item, onClick, news}) {

    return (
        <Component show={show}>
            <ArticleTitles title={item.action_title} picture={item.action_pic ? item.action_pic.url : null} firstpart={item.action_article_firstpart ? item.action_article_firstpart : null } news={news}/>
            <Article description={item.action_article} onClick={onClick} news={news} />
        </Component>
        
    )
}