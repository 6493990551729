import useViewWidth from "../../../Hooks/useViewWidth";
import styled from "styled-components";
import './ArticleMobilisation.css';
import Title from "../../../Components/Title";
import BoxMobilisationLarge from "./BoxMobilisationLarge";
import BoxMobilisationSmall from "./BoxMobilisationSmall";
import mobilisations from '../../../apiDefault/mobilisations';
import useFetchWithCache from "../../../Hooks/UseFetchWithCach";

const ArticleMobilisation = styled.article`
    background: rgba(239, 236, 236, 0.9);    
    position: relative;
    `;


const WhiteBox = styled.div`
    padding: 10vh;
    margin-bottom: 20vh;
    display:flex;
    background: white;  
    @media (max-width: 778px) {
        flex-direction: column;
        padding: 5vh;
    }
    `;

export default function ArticleMobilisationComponent(){
    const content = useFetchWithCache('/mobilisations', mobilisations);
    
    const vw = useViewWidth();
    
    return(
        <ArticleMobilisation>
            <Title h='h1' mb='1vh' ml='5vh' mt='5vh' size='8vmin'>Notre vision</Title>
            <Title h='h2' mb='5vh' ml='5vh' size='25px'>Faire d’Aix-Marseille Provence, terre d’audace et d’entrepreneurs, le moteur incontournable de l’espace Euromed-Afrique dans 4 axes et 20 domaines d’excellence !</Title>
            <WhiteBox>
                {
                    (vw > 778) 
                        ? <BoxMobilisationLarge data={content}/> 
                        : <BoxMobilisationSmall data={content}/>
                }
            </WhiteBox>
        </ArticleMobilisation>
    )
}
