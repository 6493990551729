import emailjs from 'emailjs-com';
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';


const wait = function (duration = 1000) {
    return new Promise((resolve) => {
        window.setTimeout(resolve, duration)
    })
}




export const onSubmit = async () => {
        
    
    let user_captcha = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha)===true) 
    {
       loadCaptchaEnginge(6); 
        await wait(1000);
        // console.log('HEREDFSJKHSFDHJSDF');
        // return ;
        emailjs.sendForm('service_o842w1r', 'template_dfnyncv', document.getElementsByClassName("form")[0], 'TU1OXS5DEaI7kyDzj')
        
            .then((result) => {
            console.log(result)
                if (result.status === 200){
                    document.getElementById('user_captcha_input').value = "";
                    document.getElementsByName("prenom")[0].value = "";
                    document.getElementsByName("nom")[0].value = "";
                    document.getElementsByName("email")[0].value = "";
                    document.getElementsByName("company")[0].value = "";
                    document.getElementsByName("message")[0].value = "";
                    document.getElementById("success").style.display = "block";
                    setTimeout(function(){ document.getElementById("success").style.display = "none"; }, 3000);
                }
            }, (error) => {
                console.log(error)
                document.getElementById("ErrorServer").append("Erreur serveur : " + error.text);
                document.getElementById("ErrorServer").style.display = "block";
                document.getElementById('user_captcha_input').value = "";
                setTimeout(function(){ document.getElementById("ErrorServer").style.display = "none"; document.getElementById("ErrorServer").value = ""; document.getElementById("ErrorServer").innerHTML = ""; }, 10000);
            });   
    }
    else {
        document.getElementById("errorCaptcha").style.display = "block";
        setTimeout(function(){ document.getElementById("errorCaptcha").style.display = "none"; }, 5000);
        document.getElementById('user_captcha_input').value = "";
       }
}
    
