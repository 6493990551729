import { useState, useEffect } from "react";

const getVw = () =>{
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

export default function useViewWidth() {
    const initVw = getVw();
    const [vw,setVw] = useState(initVw);

    useEffect(()=>{
        const evaluateVw = ()=>{
            setVw(getVw());
        }
        window.addEventListener('resize',evaluateVw);
        return function cleanup() {
            window.removeEventListener('resize',evaluateVw);
        };
    })
    return vw;
}