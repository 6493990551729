import styled from "styled-components";
import ArticleTitles from './components/article/ArticleTitles';
import Article from './components/article/Article';
import {useParams} from 'react-router-dom';
import useFetchWithCache from "../../Hooks/UseFetchWithCach";
import actionsDefault from '../../apiDefault/actions';
import { useHistory } from "react-router-dom";

// //--------- CSS DESCRIPTION ARTICLE --------//

const Component = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  min-height: 100vh; 
  background-color: white;
  padding: 10vh 0 10vh 0;

  @media (max-width: 770px) {
      width: 90%;
      flex-direction: column; 
  }

`;

function extractParamsUrl(ob)
    {
        ob = ob.split('&');
        var result = {};
 
        ob.forEach(function(el){
            var param = el.split('=');
            param[0] = param[0].replace('?', '');
            result[param[0]] = param[1];
        });
 
        return result;
    }

export default function EachArticle({news}) {

  let title = useParams();
  let history = useHistory();
  let ob = title.title;

  let param = extractParamsUrl(ob);
  const action = useFetchWithCache('/actions/'+param.id, actionsDefault);

  if(!action){
    return <p>LOADING</p>;
  }

  function onClick(e) { 
    e.preventDefault();

    if(history.length > 2){
        history.goBack();
    } else {
        history.push('/thematique/'+action.mobilisation.id+'');
    }
  }

  return (
    <Component>
      <ArticleTitles title={action.action_title} picture={action.action_pic ? action.action_pic.url : null} firstpart={action.action_article_firstpart ? action.action_article_firstpart : null } news={news}/>
      <Article description={action.action_article} onClick={onClick} new={news}/> 
    </Component> 
  )
            
}