import styled, {css} from "styled-components";

const TitleStyle = css`

    margin-top: ${props => props.mt ? props.mt : "0"};
    margin-right: ${props => props.mr ? props.mr : "0"};
    margin-bottom: ${props => props.mb ? props.mb : "0"};
    margin-left: ${props => props.ml ? props.ml : "0"};

    padding-top: ${props => props.pt ? props.pt : "0"};
    padding-right: ${props => props.pr ? props.pr : "0"};
    padding-bottom: ${props => props.pb ? props.pb : "0"};
    padding-left: ${props => props.pl ? props.pl : "0"};

    border-top: ${props => props.bt ? props.bt : "none"};

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: ${props => props.size ? props.size : "3vmin"};

    line-height: ${props => props.size ? props.size : "3vmin"};

    text-transform: uppercase;
    text-align: ${props => props.align ? props.align : "left"};
    
    
    transform: ${props => props.tY ? `translateY(${props.tY})` : "none"};

    width: ${props => props.maxWidth ? '100%' : "auto"};
    max-width: 100%;
    `;


const H1 = styled.h1`
    ${TitleStyle};
`;

const H2 = styled.h2`
    ${TitleStyle};
`;

const H3 = styled.h3`
    ${TitleStyle};
`;

export default function Title({children, h, size, align, tY, mt, mr, mb, ml, pt, pr, pb, pl, bt, maxWidth}) {
    let Component = null;
    switch (h) {
        case 'h1':
            Component = <H1
                mt={mt}
                mr={mr}
                mb={mb}
                ml={ml}
                pt={pt}
                pr={pr}
                pb={pb}
                pl={pl}
                bt={bt}
                tY={tY}
                size={size}
                align={align}
                maxWidth={maxWidth}>
                    {children}
            </H1>
            break;
        case 'h2':
            Component = <H2
                mt={mt}
                mr={mr}
                mb={mb}
                ml={ml}
                pt={pt}
                pr={pr}
                pb={pb}
                pl={pl}
                bt={bt}
                tY={tY}
                size={size}
                align={align}
                maxWidth={maxWidth}>
                    {children}
            </H2>
            break;
        case 'h3':
            Component = <H3
            mt={mt}
                mr={mr}
                mb={mb}
                ml={ml}
                pt={pt}
                pr={pr}
                pb={pb}
                pl={pl}
                bt={bt}
                tY={tY}
                size={size}
                align={align}
                maxWidth={maxWidth}>
                    {children}
            </H3>;
            break;
        default:
            break;
    }
    return Component;
};


