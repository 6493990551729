import { useEffect, useState } from 'react';
import styled from 'styled-components';
import contentDefault from '../../../../apiDefault/thematique'
import MarkedToHTML from '../../../../Components/MarkedToHTML';

// ----- CSS INTRODUCTION MOBILISATION ----- // 

const IntroTitle = styled.h1`
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-size: 50px;
    padding-bottom: 3vh;
    width: 90%;
    font-weight: 500;

    @media (max-width: 480px) {
        font-size: 30px;
    }
     
`;

const DivIntro = styled.div`
    padding-top: 20vh;
    padding-left: 5vw;
    margin-bottom: 8vh;

`;

const IntroText = styled.p`
    font-family: Montserrat, sans-serif;
    width: 75%;
`;


function IntroMobi () {
    const [content, setContent] = useState([]);
    useEffect(
        () => {
            if(Array.isArray(content)){
                setContent(contentDefault);
            }
            fetch(process.env.REACT_APP_API_URL + "/thematique")
                .then(response => response.json())
                .then(data => {
                    setContent(data);
                })
                .catch(error => console.log('Unable to fetch content',error));
            // eslint-disable-next-line 
            }, []
    )

    return (
        <DivIntro>
            <IntroTitle>Nos Actions</IntroTitle>
            <IntroText><MarkedToHTML>{content.description}</MarkedToHTML></IntroText>
        </DivIntro>
    )
}

export default IntroMobi;