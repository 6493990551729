import styled from "styled-components";
import Description from "./Description";
import AllTitles from '../../../Thematique/components/titles/AllTitles';


//--------- CSS SECTION DESCRIPTION MOBILISATION --------//

const SectionRow = styled.section`
    display: flex; 
    flex-direction: row;
    max-width: 90%;
    margin: 0 auto;
    padding-top: ${$props => $props.title === "Top 20, espace de <u>réflexion et d'échanges</u>" ? "4vh" : "10vh"}; 

    @media (max-width: 500px) {
       flex-direction: column;
       padding-top: 1vh;
       width: 90%;
       margin-left: 0;
       min-height: 0;
       margin-top: 8vh;
       position: relative;
    }

    @media (min-width: 501px) and (max-width: 750px) {
       max-width: 90%;
       flex-direction: column;
    }

    @media (min-width: 2000px) {
       margin-top: 35vh;
    }
`;


export default function SectionDescription (props) {
    
    return (
        <>
        <SectionRow title={props.title}>
            <AllTitles title={props.title} subTitle={props.subTitle} section={props.section} mob="mobDes" />
            <Description 
                useOneItem={props.useOneItem} 
                image={props.img}
                description={props.description}
                title={props.title}
            />
        </SectionRow>
        </>
    )
}