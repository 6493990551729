import SectionDescription from './components/mobilisations/SectionDescription';
import ButtonAction from './components/mobilisations/ButtonAction';
import SectionAction from './components/mobilisations/SectionAction';
import {useEffect} from 'react';
import actionsDefault from '../../apiDefault/actions';
import mobilisationDefault from '../../apiDefault/mobilisations';
import { useParams, useLocation } from 'react-router-dom';
import useFetchWithCache from "../../Hooks/UseFetchWithCach";
import useOneItem from '../../Hooks/useOneItem';



export default function DetailsMobilisation () {

    let location = useLocation();
    let id  = useParams();
    
    const mobilisation = useFetchWithCache('/mobilisations/'+parseInt(id.id), mobilisationDefault);
    const actions = useFetchWithCache('/actions', actionsDefault);
    const [itemAction, saveItemAction] = useOneItem(actions, 'mobilisation');

    useEffect(() => {
        setTimeout(function(){ window.scrollTo(0, 0); }, 0);
    }, [location]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        saveItemAction(parseInt(id.id))
    }, []);


    if(!mobilisation){
        return <p>LOADING</p>;
    }
  

    return (         
            <section>
            <SectionDescription 
                title={mobilisation.mobilisation_title}
                subTitle={mobilisation.mobilisation_subtitle}
                img={mobilisation.mobilisation_card_pic}
                description={mobilisation.mobilisation_description}
                section="section"
                useOneItem={useOneItem} 
            /> 
                    
            <ButtonAction id={mobilisation.id} />

         { itemAction && <SectionAction itemAction={itemAction}  /> }

         </section>
    )
}
