import styled from "styled-components";
import SectionPresentation from './Components/SectionPresentation';
import Citation from '../Home/Components/Citation';
import bgImage from "../../images/CMA.jpg";
import ArticleEnChiffreComponent from "./Components/ArticleEnChiffre";
import ArticleEtreEtMissionComponent from "./Components/ArticleEtreEtMission";
import ArticleMobilisationComponent from "./Components/ArticleMobilisation";
import News from "./Components/News";
import homeDefault from "../../apiDefault/home";
import useFetchWithCache from "../../Hooks/UseFetchWithCach";

const ImgBackground = styled.img`
    position: absolute;
    -webkit-filter: grayscale(100%); 
    object-fit: cover;
    object-position: 80% 100%;
    height: 100%;
    width: 100%;`;


const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative
    `;


export default function Home() {
    const content = useFetchWithCache('/home', homeDefault);
    
    return (
        <>
            <SectionPresentation description={content.welcomeDescription} />
            <Citation content={content} />   
            <Wrapper>
                <ImgBackground src={bgImage }/>
                <ArticleEnChiffreComponent content={content} />
                <ArticleEtreEtMissionComponent content={content} />
                <ArticleMobilisationComponent />
            </Wrapper>
            <News />
        </>
    )
}
