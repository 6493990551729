import styled from 'styled-components';
import WrapperFlex from '../../../Components/WrapperFlex';
import Title from '../../../Components/Title';
import {ReactComponent as Previous} from '../../../images/icon-previous.svg';
import {ReactComponent as Next} from '../../../images/icon-next.svg';
import MarkedToHTML from '../../../Components/MarkedToHTML';
import troncateText from '../../../Lib/troncateText'
import { useState } from 'react';
import Modal from '../../../Components/Modal';
import ModalArticle from '../../../Components/EachArticle';
import useModal from '../../../Hooks/useModal';
import Button from '../../../Components/Button';


const CarouselContainer = styled(WrapperFlex)`
    margin: 5vh 0 ;
    padding: 0 5vh ;
    @media (max-width: 767px) {
        padding: 0;
    }
   
`;

const NavButton = styled(WrapperFlex)`
    padding: 3vh;
    @media (max-width: 767px) {
        padding: 2vh;
    }
`;

const MainContent = styled(WrapperFlex)`
    @media (max-width: 767px) {
        flex-direction: column;
    }
    `;

const Side = styled(WrapperFlex)`
    width: 50%;
    height: 70vh;
    padding: 5vh;
    @media (max-width: 767px) {
        width: 100%;
        height: auto;
        padding: 0;
    }
    `;

const SidePic= styled(Side)`
    @media (max-width: 767px) {
        order:1;
        margin-bottom: 3vh;
    }`;

const SideText= styled(Side)`
    display:flex;
    align-content: space-between;
    @media (max-width: 767px) {
        order:2;
    }`;

const Img = styled.img`
    object-fit: contain;
    height: 60vh;
    width: 100%;
    @media (max-width: 767px) {
        height: 45vh;
    }
    `;

const Article = styled.article`
    position: relative;
    text-align: justify;
    font-family: Montserrat, sans-serif;
    line-height: 18px;
    max-height: 50%;
    margin-top: 10vh;
    `;


export default function Carousel({data}){
    const [selected,setSelected] = useState(0);
    const [visible,displayModal,closeModal] = useModal();
    
    if(!data[selected]) return null;

    const item = {
        "action_title": data[selected].title,
        "action_pic": {
            "url": data[selected].cover.url
        },
        'action_article': data[selected].content,
    }

    


    return(
        <CarouselContainer direction='row' noWrap>
            <NavButton>
                {selected>0 && <Previous onClick={()=>selected-1>=0?setSelected((selected)=>selected-1):null}/>}
            </NavButton>
            <MainContent direction='row' position='flex-start' noWrap>
                <SideText>
                    <Title h='h1' ><MarkedToHTML>{data[selected].title}</MarkedToHTML></Title>
                    <Article><MarkedToHTML>{troncateText(data[selected].content,300)}</MarkedToHTML></Article>
                    <Button className="pointer" onClick={displayModal}>En savoir plus</Button>
                </SideText>
                <SidePic>
                    <Img src={process.env.REACT_APP_API_URL+data[selected].cover.url} alt="news" />
                </SidePic>
            </MainContent>
            <NavButton>
                {selected<data.length-1 && <Next onClick={()=>selected+1<data.length?setSelected((selected)=>selected+1):null}/>}
            </NavButton>
            <Modal show={visible} onClick={closeModal}>
                <ModalArticle onClick={closeModal} item={item} news/>
            </Modal>
        </CarouselContainer>
    )
}