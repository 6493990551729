import IconLocalisation from "../images/icon-localisation.svg";
import WrapperFlex from "./WrapperFlex";
import Icon from "./Icon";
import styled from "styled-components";

const Address= styled.address`
    width: 100%;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-size: 1.3vh;
    text-align: center;`;

const Wrapper = styled(WrapperFlex)`
    width: 50%;
        @media (max-width: 767px) {
            width: 75%;
        }
`;
export default function AddressComponent({children}) {
    return(
        <Wrapper direction='column' mt='2vh'>
            <Address>{children}</Address>
            <Icon size='3vh' src={IconLocalisation}/>
        </Wrapper>
    )
}