import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";



export default function useModal() {
    const [visible, setVisible] = useState(false);
    const [yPos, setYPos] = useState(0);
    const valueVisible = useRef();
    let history = useHistory();

    // Only way to access parametre visible in cleanup fonction from useEffect
    useEffect(() => {
        valueVisible.current = visible;
      }, [visible]);

    const onBackButton = () => {
        if(valueVisible.current){
            closeModal();
            history.goForward();
        }
    };

    // Handle back button when url change
    useEffect(() => {
        return () => {
            onBackButton();
        }
        // eslint-disable-next-line
    }, []);

    // Handle back button when hash change ()
    window.onhashchange = onBackButton;

    const displayModal = () => {
        setVisible(true);
        setYPos(window.scrollY);
    }

    const closeModal = () => {
        setVisible(false);
        setTimeout(() => {
            window.scrollTo(0,yPos);
        } ,500);
    }

    return [
        visible,
        displayModal,
        closeModal
    ];
}