import { Link } from "react-router-dom";
import styled from "styled-components";
import MarkedToHTML from "../../../../Components/MarkedToHTML";

//--------- CSS DESCRIPTION ARTICLE --------//

const DivDescription = styled.div`
    width: 50%;
    min-height: 80vh;
    margin-left: 4vw;
    position: relative;

    @media (max-width: 770px) {
        position: static;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 0;
    }
`;

const TextDescription = styled.p`
    width: 100%;
    font-family: Montserrat, sans-serif;
    line-height: 18px;
    margin-top: 10vh;
    position: absolute;
    bottom: 0;
    font-size: 13px;
    text-align: justify;

    @media (max-width: 770px) {
        position: static;
        margin-top: 5vh;
        width: 100%;
    }

`;

const Close = styled(Link)`
    float: right;
    padding: 3vh;
    text-decoration: none;
    color: black;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    transition: all 0.3s ease-in;
    font-size: 1vw;
    margin-top: 10vh;

    &:hover {
        color: #f83030a1;
    }

    @media (max-width: 770px) {
        margin-top: 5vh;
        float: left;
        padding: 0 2vw 1vh 2vw;
        font-size: 2.5vw;
        
    }

    @media (min-width: 771px) and (max-width: 950px) {
        margin-top: 0;
        padding: 2vh 0 6vh 0;
        font-size: 1.5vw;

    }
`;


export default function Description ({description, onClick, news}) {
    return (
        <DivDescription>
            {!news && <Close onClick={(e) => {onClick(e)}} ><i className="fas fa-chevron-left"></i> retour </Close>}
            <TextDescription><MarkedToHTML>{description}</MarkedToHTML></TextDescription>
        </DivDescription>
    )
}