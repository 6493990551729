import styled from "styled-components";
import Title from "../../../Components/Title";

const ArticleEnChiffre = styled.article`
    background: rgba(239, 236, 236, 0.79);    
    position: relative;
    `;

const Red = styled.u`
    text-decoration: none;
    color: #D12834`;

const ChiffreWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left:6rem;
    @media (max-width: 778px) {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin:0;
        padding:0;
    }
    `;

const Chiffre = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding-right: 6rem;
    margin-bottom: 10vh;
    @media (max-width: 778px) {
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 5vh;
        margin-left: 5vh;
        padding:0;
    }
    `;

const ChiffreTitle = styled.h3`
    position: absolute;
    transform: rotateZ(-90deg);
    transform-origin: bottom left;
    bottom: 0;
    text-transform: uppercase;
    font-size: 1.7rem;
    `;

const ChiffreBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left:15px;
    margin-left:15px;
    border-left:8px solid #D12834;
    overflow:hidden;
    @media (max-width: 778px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    `;

const ChiffreSubTitle = styled.h4`
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 2rem;
    `;

const ChiffreValue = styled.p`
    font-weight: bold;
    font-size: 4rem`;


export default function ArticleEnChiffreComponent({content}){
    return(
        <ArticleEnChiffre>
            <Title h='h1' ml='5vh' tY='-50%' size='8vmin'>LE TOP 20 <br /><Red>EN CHIFFRES</Red></Title>
            <ChiffreWrapper>
                <Chiffre>
                    <ChiffreTitle>Membres</ChiffreTitle>
                    <ChiffreBox>
                        <ChiffreSubTitle>Dirigeants</ChiffreSubTitle>
                        <ChiffreValue>{content.membersNumber}</ChiffreValue>
                    </ChiffreBox>
                </Chiffre>
                <Chiffre>
                    <ChiffreTitle>Emplois</ChiffreTitle>
                    <ChiffreBox>
                        <ChiffreSubTitle>Collaborateurs</ChiffreSubTitle>
                        <ChiffreValue>{content.workersNumber}</ChiffreValue>
                    </ChiffreBox>

                </Chiffre>
                <Chiffre>
                    <ChiffreTitle>Chiffre<br />d'affaires</ChiffreTitle>
                    <ChiffreBox>
                        <ChiffreSubTitle>Chiffre d’affaires<br />cumulé</ChiffreSubTitle>
                        <ChiffreValue>{content.salesNumber}</ChiffreValue>
                    </ChiffreBox>
                </Chiffre>
            </ChiffreWrapper>
        </ArticleEnChiffre>
    )
}