import {BrowserRouter as Router} from 'react-router-dom';
import Pages from './Components/Pages';
import ErrorBoundary from './Components/ErrorBundary';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer/Footer.js';
import LogoBar from './Components/LogoBarComponent';
import ScrollToTop from './Components/ScrollToTop';
import styled from 'styled-components';

const Div = styled.div`
  position:relative;
  max-width: 1935px;
  @media (min-width: 1935px) {
    border-left:1px solid lightgrey;
    border-right:1px solid lightgrey;
    margin: auto;
    }
    `;

function App() {
 
  return (
    <Div className="App">
      <ErrorBoundary>
        <Router>
          <ScrollToTop />
          <NavBar />
          <Pages />
          <LogoBar />
          <Footer />
        </Router>
      </ErrorBoundary>
    </Div>
  );
}

export default App;

