import styled from "styled-components";

//section mobilisation
const Sub = styled.h2`
    font-family: Montserrat, sans-serif;
    font-size: ${$props => $props.css ? '15px' : '16px'};
    width: ${$props => !$props.css && '30vw'};
    text-align: ${$props => !$props.css && 'right'};
    margin-top: ${$props => !$props.css && '2vh'};
    float: ${$props => $props.css ? 'left' : 'right'};
    padding: ${$props => $props.css && '4vh 5vh 1vh 3vh'};
    font-weight: 400;

    @media (max-width: ${$props => !$props.css && '600px'}) {
       width: ${$props => !$props.css && '80vw'};
    }

    @media (max-width: ${$props => $props.css && '500px'}) {
        padding: ${$props => $props.css && '4vh 4vh 1vh 3vh'}
    }

    @media (min-width: ${$props => $props.css && '501px'}) and (max-width: ${$props => $props.css && '900px'}) {
        padding: ${$props => $props.css && '1vh 4vh 1vh 3vh'};
        font-size: ${$props => $props.css && '13px'};
    }

    @media (min-width: ${$props => $props.css && '901px'}) and (max-width: ${$props => $props.css && '1140px'}) {
        padding: ${$props => $props.css && '1vh 4vh 1vh 3vh'}
    }

    @media (min-width: ${$props => $props.mob && "501px"}) and (max-width: ${$props => $props.mob && "750px"}) {
        width: ${$props => $props.mob && "85%"};
    }
`;


export default function SubTitle ({subTitle, css, mob}) {

    return <Sub css={css} mob={mob}>{subTitle}</Sub>
}