import styled from 'styled-components';
import { Link } from "react-router-dom";

const DisplayBoxLink = styled(Link)`
    padding-top:0.75vh;
    font-size: 2vh;
    background-color: transparent;
    border: none;
    border-top: 2px solid #D12834;
    color: black;
    text-transform: uppercase;
    margin-top: 10vh;
    transition: all 0.3s ease-in;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        border-top: 2px solid black;
        color:#f83030a1;
    }
    
`;

export default function ButtonDefault(props){

    return (
        <DisplayBoxLink to={props.link}>{props.text}</DisplayBoxLink> 
    )
}