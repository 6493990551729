import ContactForm from "./ContactForm"
import Legal from "./Legal";
import styled from "styled-components";
import VerticalSeparator from "./VerticalSeparator";
import Media from "./Media";

const Footer = styled.footer`
    position:relative;
    padding-top: 50px;
    background-color: #D12834;
    color: #FDFBFB;`;

const Section = styled.section`
    background-color: ${props => props.dark ? "rgba(106, 4, 15, 0.3)" : "transparent" };
    padding:20px;
    display: flex;
    justify-content: flex-around;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: ${props => props.center ? "center" : "left" };`;

export default function FooterComponent(){
    return (
        <Footer id='contact'>
            <Section center>
                <Media />
                <VerticalSeparator />
                <ContactForm />
            </Section>
            <Section dark>
                <Legal />
            </Section>
        </Footer>
    )
}