import styled from "styled-components";
import Card from "../../Components/Card";

const CardsWrapper = styled.div`
    margin-top: -2vmin;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10vh;
    @media (max-width: 778px) {
        margin-top: 5vh;
        margin-bottom: 0vh;
        row-gap: 5vh;
    }
`;

export default function Members({content, gouv}){
    return(
        <CardsWrapper >
            {
               content.map((member,index)=><Card  key={'member-'+index} member={member} gouv={gouv}/>)
            }
        </CardsWrapper>
    )
}