import styled from 'styled-components';
import homeScreen from '../../../images/homeScreen.png';
import bigLogo from '../../../images/LOGO-TOP20-BIG.svg';
import MarkedToHTML from '../../../Components/MarkedToHTML';

// ------------- Styled Welcome Section Presentation TOP20 -------------  //

// ------- style WELCOME IMAGE ------- //
const Section = styled.section`
position: relative;
height:100vh;
width: 100%;
z-index: 7;                                                                                                                                                                         
background-image: url(${homeScreen});
background-position:center;
background-repeat: no-repeat;
background-size:cover;`;

// --------- style CONTAINER RED SQUARE --------- //
const Article = styled.article`
width: 43%;
height:49%;
padding: 25px 25px 25px 25px;
position: absolute;
top:43%;
left:1%;
@media (max-width: 778px) {
    padding:0;
    position: absolute;
    bottom:0;
    left:0;
    top:51vh;
    width:100%;
}`;

// --------- style RED CALC (red aspect) --------- //
const Calc = styled.div`
position: absolute;
width: 100%;
height:100%;
background-color:#f83030a1;
background-blend-mode: overlay;`;

// ------- style CONTAINER Biglogo ON RED SQUARE ------- //
const Biglogo = styled.div`
position :absolute;
bottom: 34vh;
margin-left:2rem;
@media (max-width: 1007px) {
        bottom: 36vh;}

@media (max-width: 778px) {
        bottom: 33vh;}

@media (max-width: 450px) {
        bottom: 38vh;}`;

// ------- style TEXT INTRODUCTION ------- //
const Span = styled.span`
position :absolute;
bottom:0%;
left:0%;
color:white;
z-index:1;
opacity:1;
margin:2rem;
line-height: 1.1rem;
letter-spacing:0.1rem;
`;

export default function SectionPresentation({description}){
    return(
        <>
        <Section>   
                <Article>
                    <Calc> 
                        <Biglogo>
                            <img width="80%" height="100%" src={bigLogo} alt="gros logo"></img> 
                        </Biglogo>
                        <Span><MarkedToHTML>{ description }</MarkedToHTML></Span>
                    </Calc>
                </Article>
            </Section>
        </>
    )
}