import styled from 'styled-components';

const Button = styled.button`
    padding-top:0.75vh;
    font-size: 2vh;
    background-color: transparent;
    border: none;
    border-top: 2px solid #D12834;
    color: black;
    text-transform: uppercase;
    margin: 5vh;
    transition: all 0.3s ease-in;
    cursor: pointer;

    &:hover {
        border-top: 2px solid black;
        color:#f83030a1;
    }
`;



export default Button;
