import styled from "styled-components";
import { Link } from 'react-scroll';

//--------- CSS BUTTON NOS ACTIONS --------//

const DivButton = styled.div`
    width: 10vw;
    margin: 0 auto;
    padding-top: 10vh;
    padding-bottom: 5vh;

    @media (max-width: 500px) {
       width: 25vw;
       padding-top: 0;
       
    }

    @media (min-width: 501px) and (max-width: 750px) {
       width: 20vw;
    }
`;

const Linked = styled(Link)`
    text-decoration: none;
    color: rgb(70, 70, 70);
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1vw;

    @media (max-width: 500px) {
        font-size: 3vw;
    }

    @media (min-width: 501px) and (max-width: 750px) {
        font-size: 2vw;
    }

`;

const Chevron = styled.p`
    width: 4vw;
    margin: 0 auto;

    @media (max-width: 500px) {
       width: 6vw;
    }

    @media (min-width: 501px) and (max-width: 750px) {
        width: 7vw;
    }
`;


export default function ButtonAction({id}) {

    return (
        <>
        <DivButton>
            <Linked activeClass="active" to="allAction" spy={true} smooth={true} duration={1000} id={id}>Nos actions</Linked>
            <Chevron><i style={{color: 'rgb(70, 70, 70)'}} className="fas fa-chevron-down"></i></Chevron>
        </DivButton>
        </>
    )
}