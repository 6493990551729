import styled from "styled-components";

const ModalComponent = styled.div`
    display: ${$props => $props.show ? "block" : "none"};
    position: absolute;
    z-index: 777;
    top: 0;
    left: 0;
    background-color: white;
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    `;

const Title = styled.h1``;

const Content = styled.div`
    height: 100%`;

const Close = styled.a`
  position: absolute;
  right: 0;
  padding: 3vh;
  cursor: pointer;
  @media (max-width: 767px) {
        padding: 1.5vh;
    }

`;

export default function Modal({children, title, show, onClick}) {
  return (
    <ModalComponent show={show} >
      <Close onClick={onClick} ><i style={{color: 'rgba(126, 126, 126)', fontSize: '30px', }} className="fas fa-times"></i></Close>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </ModalComponent>
  );
}