import { useEffect, useRef } from "react";
import styled from "styled-components";
import defaultCompanies from "../apiDefault/companies"
import useFetchWithCache from "../Hooks/UseFetchWithCach";

const LogoBar = styled.div`
    display: flex;
    flex-direction: row;
    width: 99.9%;
    overflow: scroll;
    height: 150px;    
`; 

const Logo = styled.img`
    align-self: center;
    max-height: 50%;
    width: 200px;
    margin-right: 40px;
}`;

export default function LogoBarComponent(){
    const content = useFetchWithCache('/companies?_sort=name:ASC', defaultCompanies);

    const logoEl = useRef();

    const scrollInfinite = (el) => {
        return window.setInterval(() => {
            if ((el.scrollLeft + el.offsetWidth) < el.scrollWidth) {
                el.scrollLeft += 1;
            } else {
                el.scrollLeft = 0;
            }
        },  10);
    };

    useEffect(()=>{
        let timerId = null;
        if(logoEl.current){
            timerId = scrollInfinite(logoEl.current);
        }
        return function cleanUp() {
            window.clearInterval(timerId);
        }
    },[content]);
       
    return (
        <LogoBar ref={logoEl} className="logobar">
            { content.map( (company,index) => {
                    if(company.logo && (company.companyMember || (company.companyMember == null))){
                        return <Logo key={'logo-company-'+index} src={process.env.REACT_APP_API_URL + ''+company.logo.url} alt={'logo '+company.name}/>
                    } else {
                        return null;
                    }
                }) }
        </LogoBar>
    )
}