import Card from './Card';

export default function CardOneMob ({mob, onClick, saveItem}) {

    return (
        <Card 
        card="card" 
        title={mob.mobilisation_title} 
        subTitle={mob.mobilisation_subtitle} 
        picture={mob.mobilisation_card_pic} 
        id={mob.id} 
        onClick={onClick}
        setteur={saveItem} 
        />
    )
}