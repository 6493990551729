import styled from "styled-components";
import Members from "./Members";
import Title from "../../Components/Title";
import membersDefault from '../../apiDefault/members';
import contentDefault from '../../apiDefault/club';
import { Link } from 'react-scroll';
import useFetchWithCache from "../../Hooks/UseFetchWithCach";


const SectionCover = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20vh 10vh 10vh 10vh;
    @media (max-width: 778px) {
        padding: 20vh 5vh 5vh 5vh;
    }
    `;

const Nav = styled.nav`
    margin-top: 8vh;
    display:flex;
    flex-direction:row;
    justify-content:center;
    @media (max-width: 778px) {
        flex-direction:column;
    }
    `;

const Red = styled.span`
    color:#D12834;
    `;

const RedBar = styled.div`
    width: 100%;
    height: 2px;
    background-color: #D12834;;
    margin: 1vh 0 10vh 0;
    @media (max-width: 778px) {
        margin-bottom: 1vh;
    }
    `;

const Section = styled.section`
    padding: 0 10vh;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    @media (max-width: 778px) {
        padding: 0 5vmin;
    }
    `;

const FirstBlock = styled.div`
    padding-top:10vh;
    padding-right: 10vh;
    @media (max-width: 778px) {
        padding-right: 0;
    }
    `;

const SecondBlock = styled.div`
    padding-left: 30%;
    @media (max-width: 778px) {
        padding-left: 40%;
    }
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    `;


const Description = styled.p`
    margin: auto;
    text-align: center;
    width: 65%;
    font-family: Montserrat, sans-serif;
    padding-top: 2vh;

    @media (max-width: 600px) {
       width: 100%;
    }
`;

const ButtonDefault = styled(Link)`
    justify-self:center;
    width: 200px;
    text-align: center;
    margin: 0 2.5vh;
    @media (max-width: 778px) {
        width: 125px;
        margin: 0 auto 3vh auto;
    }
    padding-top:1vh;
    font-size: 18px;
    border-top: 2px solid #D12834;
    color: black;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease-in;

    &:hover {
        border-top: 2px solid black;
        color:#f83030a1;
    }
    `;


export default function Club(){

    const content = useFetchWithCache('/club',contentDefault)

    const members = useFetchWithCache('/members?_sort=surname:ASC,name:ASC',membersDefault)

    if(!members){
        return <p>LOADING</p>;
    }


    const PresidentFilter = (members) => members.gouvernance_position === 'Président';
    const TresorierFilter = (members) => members.gouvernance_position === 'Trésorier';
    const SecretaireFilter = (members) => members.gouvernance_position === 'Secrétaire';
    const AdministrateurFilter = (members) => members.gouvernance_position === 'Administrateur';
    const SecretariatFilter = (members) => members.gouvernance_position === 'Secrétaire générale';
    const MembreFilter = (members) => (members.company && (members.company.name !== 'Deloitte')) && (members.company && (members.company.name !== 'Club top 20'));
    const PartenaireFilter = (members) => (members.company && (members.company.name === 'Deloitte'));

    
    return (
        <main>
            <SectionCover>
                <Title h='h1' align='center' size='9vmin'>Le Club <Red>top 20</Red></Title>
                <Description>{content.description}</Description>
                <Nav>
                    <ButtonDefault activeClass="active" to="gouvernance" spy={true} smooth={true} duration={1000}  >la gouvernance </ButtonDefault>
                    <ButtonDefault activeClass="active" to="membres" spy={true} smooth={true} duration={1000}   >Les membres</ButtonDefault>
                </Nav>           
            </SectionCover>
            <Section id="gouvernance">
                <FirstBlock>
                    <Title h='h1' pt='40px' size='4.5vmin'>La gouvernance </Title>
                    <RedBar />
                    <Title h='h2' size='2vmin'>Le Conseil d’administration </Title>
                </FirstBlock>
                <SecondBlock>
                        <Members content={members.filter(PresidentFilter)} gouv/>
                        <Members content={members.filter(TresorierFilter)} gouv/>
                        <Members content={members.filter(SecretaireFilter)} gouv/>
                        <Members content={members.filter(AdministrateurFilter)} />
                </SecondBlock>
            </Section>
            <Section >
                <FirstBlock>
                    <Title h='h2' size='2vmin'>Secrétariat général</Title>
                </FirstBlock>
                <SecondBlock>
                        <Members content={members.filter(SecretariatFilter)} />
                </SecondBlock>
            </Section>
            <Section  id="membres">
                <FirstBlock>
                    <Title h='h1' pt='40px' size='4.5vmin'>Les membres</Title>
                    <RedBar />
                </FirstBlock>
                <SecondBlock>
                    <Members content={members.filter(MembreFilter)} />
                </SecondBlock>
                <FirstBlock>
                    <Title h='h2' size='2vmin'>Deloitte <br />Partenaire du club top 20</Title>
                </FirstBlock>
                <SecondBlock>
                    <Members content={members.filter(PartenaireFilter)} />
                </SecondBlock>
            </Section>
 
        </main>
    )
}