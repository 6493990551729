import React, { Component } from 'react';
import styled from "styled-components";
import { loadCaptchaEnginge, LoadCanvasTemplate } from 'react-simple-captcha';



class CaptchaTest extends Component {

   componentDidMount () {
      loadCaptchaEnginge(6, "#D12834", "black"); 
   };

   doSubmit = () => {
       
   };

   render() {
return (
        <Modal>
           <div className="container">
               <div className="form-group">
                   
                   <Div>
                       <LoadCanvasTemplate reloadText="Change le Captcha" reloadColor="lightgrey" />
                   </Div>

                   <div className="col mt-3">
                       <Input placeholder="Entrer le captcha ci-dessus" id="user_captcha_input" name="user_captcha_input" type="text"></Input>
                   </div>

               </div>
           </div>
       </Modal>
       );
   };
}

const Modal = styled.div`
    padding: 5px;
`;

const Input = styled.input`
    background-color: transparent;
    border: none;
    width: 100%;
    border-bottom: 1px solid white;
    padding: 20px 20px 10px 0;
    &::placeholder { 
        color: #FDFBFB; /* Kind of white */
        opacity: 1; /* Firefox */
        font-size: 0.8rem;
}`;

const Div = styled.div`
    text-decoration: underline;
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
`;

export default CaptchaTest;