import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Club from '../Pages/Club/Club.js';
import Thematique from '../Pages/Thematique/Thematique.js';
import Home from '../Pages/Home/Home.js';
import NotFound from '../Pages/404/404.js';
import Member from '../Pages/Member/Member.js';
import ThematiquesDetail from '../Pages/ThematiqueDetail/ThematiquesDetail';
import Action from '../Pages/Action/Action.js';

export default function Page({pages}){



    return(
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path={["/club","/club.html"]} component={Club} />
          <Route path={["/thematiques","/thematiques.html"]} component={Thematique} />
          <Route path="/thematique/:id" component={ThematiquesDetail} />
          <Route path="/member/:name" component={Member} />
          <Route path="/action/:title" component={Action} />
          <Route path="*" component={NotFound} />
        </Switch>
    )
}



