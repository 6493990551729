import { useState } from "react";

// --- Generic function to select a particular data item in relation to an element ----//

export default function useOneItem(items, mobilisation = null) {
    const [oneItem, setOneItem] = useState(null);

    function saveItem(id) {
        setOneItem(mobilisation === 'mobilisation' ? items.filter((items) => items.mobilisation.id === id) : 
            items.filter((items) => items.id === id)[0]);
    }

    return [oneItem, saveItem];
}