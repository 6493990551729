import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MarkedToHTML from "../../../Components/MarkedToHTML";
import Button from "../../../Components/Button";

const DisplayBox = styled.details`
    display: flex;
    margin-bottom: 3vh;
    border-left: 8px solid white;
    `;
    
const Summary = styled.summary`
    margin: 2vh;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.3rem;
    `;

const DisplaySubThematic = styled.h2`
    margin: 2vh;
    font-family: Montserrat;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    `;



const LinkStyled = styled(Link)`
    text-align:center;
`;

export default function BoxMobilisationSmall({data}){
    
    const [selected, setSelected] = useState(null);
    const handleOnClick = (index) => {


        console.log(index)


        if(selected === index){

            setSelected(null);
            return;
        }
        setSelected(index)
        data.map((item,index)=> {
            document.querySelector('#details-'+index).removeAttribute('open');
            return null;
        })
    }
    return(
        <div className="thematique">
            { data.map( (item,index) => 
                <DisplayBox 
                    className={ index === selected ? 'details-selected' : '' }
                    key={index} 
                    id={'details-'+index}
                    onClick={()=>handleOnClick(index)}>

                    <Summary ><MarkedToHTML>{item.mobilisation_title}</MarkedToHTML></Summary>
                    <DisplaySubThematic>{data[index] && data[index].mobilisation_subtitle}</DisplaySubThematic>
                    <LinkStyled to={"/thematiques" }>
                        <Button>En savoir plus</Button>
                    </LinkStyled>

                </DisplayBox> ) }
        </div>
    )
}
