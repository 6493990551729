import styled from 'styled-components';
import Card from './Card';

// ----- CSS DIV CARD ACTION----- // 

const DivRow = styled.div`
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (max-width: 500px) {
        flex-direction: column;
    }

    @media (min-width: 501px) and (max-width: 750px) {
        gap: 5px;
        width: 95%;
    }
`;


export default function CardAction ({itemAction}) {

    return (
        <DivRow>
        { itemAction.map((arrayAction, index) => {

            return (
                <Card key={index}
                card="card" 
                action="action" 
                index={index} 
                title={arrayAction.action_title} 
                picture={arrayAction.action_pic} 
                id={arrayAction.id} 
                 />
            );
        })
        }
        </DivRow>
    )
}