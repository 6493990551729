import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import MarkedToHTML from "../../../Components/MarkedToHTML";
import Button from '../../../Components/Button'

const Nav = styled.nav`
    width: 50%;
    `;

const DisplayBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    `;

const Ul= styled.ul`
    list-style: none;
    `;

const Li = styled.li`
    padding: 2vh 10vh 2vh 5vh; 
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 0.1em;
    `;

const DisplayBoxTitle = styled.h1`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-style: normal;
    font-size: 3vmin;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    @media (min-width: 778px) {
        padding: 0 0 5vh 5vh;
        text-align: left;
    }
    margin: 0;
    `;

const LinkStyled = styled(Link)`
    width: 100%;
    text-align: center;
    `;

export default function BoxMobilisationLarge({data}){
    const [selected, setSelected] = useState(1);
    if(!data){
        return null;
    }
    return(
        <>
            <Nav className="thematique">
                <Ul>
                    { data.map( (item,index) => 
                    <Li 
                        key={index} 
                        className={ index === selected ? 'selected' : '' }
                        onMouseEnter={()=>setSelected(index)}><MarkedToHTML>{item.mobilisation_title}</MarkedToHTML>
                    </Li> ) }
                </Ul>
            </Nav>
            <DisplayBox>
                <DisplayBoxTitle>{data[selected] && data[selected].mobilisation_subtitle}</DisplayBoxTitle>
                <LinkStyled to={"/thematiques"}>
                    <Button>En savoir plus</Button>
                </LinkStyled>
            </DisplayBox>
        </>
    )
}