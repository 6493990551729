import CardMobilisation from './components/cards/CardMobilisation';
import useFetch from '../../Hooks/useFetch';
import mobilisationsDefault from '../../apiDefault/mobilisations';

export default function Thematique(){

    const [loading, items] = useFetch(process.env.REACT_APP_API_URL + '/mobilisations');
    const mobilisations = loading ? mobilisationsDefault : items;
 
    return (
        <>
        <CardMobilisation content={mobilisations} />
        </>
    )
}