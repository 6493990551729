import styled from "styled-components"

const StyledLink = styled.a`
    color:white;`;

export default function Legal(){
    return(
        <details>
            <summary>Mentions legales</summary>
            <p>Pour toute remarque sur le fonctionnement du site, utilisez le formulaire de contact.</p>

            <p>Ce site a été réalisé par:<br />
            La Plateforme - La Plateforme_ Marseille | Centre de Formation<br />
            <StyledLink href="https://laplateforme.io">https://laplateforme.io</StyledLink></p>

            <p>Ce site est hébergé par OVH – 2 rue Kellermann 59100 Roubaix – www.ovh.com</p>
        </details>
    )
}