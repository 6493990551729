import styled from 'styled-components';
import logoTop20 from '../../images/LOGO-TOP20-BIG.svg';
import logoTwitter from '../../images/Twitter.svg';
import logoLinkedin from '../../images/Linkedin.svg';
import logoDeloitte from '../../images/DEL_SEC_RGB.png';

const Media = styled.div`
    margin-bottom: 50px;
    min-width: 300px;
    padding: 0 5vh 0 5vh;
    @media (min-width: 778px) {
        width: 40%;
    }
    `;

const LogoBig = styled.img`
    margin-top: 40px;
    width: 100%`;

const LogoSmall = styled.img`
    margin: 10px 10px 0 0;
    width: 50px;
    height: 50px;`;

const LogoDeloitte = styled.img`
    margin: 50px 0px -20px -25px;
    width: 200px;
`;    

const SloganDeloitte = styled.p`
    color: lightgray;
    `;


export default function MediaComponent(){
    return(
        <Media>
            <LogoBig src={logoTop20} />
            <nav>
                <a href="https://twitter.com/ClubTop20" target="_blank" rel="noreferrer">
                    <LogoSmall src={logoTwitter} />
                </a>
                <a href="https://www.linkedin.com/company/club-top-20/" target="_blank" rel="noreferrer">
                    <LogoSmall src={logoLinkedin} />
                </a>
            </nav>
            <LogoDeloitte src={logoDeloitte} />
            <SloganDeloitte>Partenaire du Clup Top 20</SloganDeloitte>
        </Media>
    )
}