import styled from "styled-components";
import MarkedToHTML from "../../../../Components/MarkedToHTML";


const DivTitle = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    min-height: 80vh;

    @media (max-width: 770px) {
        width: 90%;
    }
`;

const RedLine = styled.div`
    width: 25vw;
    height: 0.7vh;
    background-color: #D12834;
    margin: 2vh 0 3vh 0;

    @media (max-width: 770px) {
        width: 50vw;
        height: 0.5vh;
    }
`;

const Title = styled.h1`
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 2vh;
    padding-top: 10vh;
    
    @media (max-width: 770px) {
        font-size: 20px;
        padding-top: 2vh;
    }
`;

const Img = styled.img`
    width: ${$props => $props.alt === 'Une mobilisation collective du monde économique pour construire une métropole forte et ambitieuse (novembre 2020)' ? '20%' : '100%'};
    margin-top: 2vh;

    height: ${$props => $props.news ? "80vh" : "auto"} ;
    object-fit: ${$props => $props.news ? "cover" : "auto"} ;

    @media (max-width: 770px) {
        width: 100%; 
        margin: 0 auto;
    }
`;

const TextDescription = styled.p`
    width: 100%;
    font-family: Montserrat, sans-serif;
    line-height: 18px;
    padding-top: 4vh;
    color: black;
    font-size: 13px;
    text-align: justify;

    @media (max-width: 770px) {
        width: 100%; 
    }

`;


export default function ArticleTitles ({title, picture, firstpart, news}) {

    return (
        <DivTitle>
            <Title><MarkedToHTML>{title}</MarkedToHTML></Title>
            <RedLine></RedLine>
            { picture ? <Img src={process.env.REACT_APP_API_URL+picture} alt={title} news={news}/> : null }
            { firstpart ? <TextDescription><MarkedToHTML>{firstpart}</MarkedToHTML></TextDescription> : null}
        </DivTitle>
    )
}