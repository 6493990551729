export default function troncateText(string, chars){
    if(string && chars && Number.isInteger(chars) && string.length > chars){
        let index = chars;
        while (string[index] !== ' ' && index < string.length) {
            ++index;
        }
        return string.slice(0, index)+'...';
    } else {
        return string;
    }
}