import styled from "styled-components";
import MarkedToHTML from "../../../../Components/MarkedToHTML";

const Title = styled.h1`
    font-family: Montserrat, sans-serif;
    font-size: ${$props => $props.css ? '25px' : '40px'};
    font-size: ${$props => $props.action && '18px'};
    text-transform: uppercase;
    text-align: ${$props => $props.css ? 'left' : 'right'};
    margin-bottom: 2vh;
    padding: ${$props => $props.css ? '3vh 3vh 1vh 3vh' : '0'};
    font-weight: 500;

    @media (max-width: 600px) {
       font-size: 20px;
    }

    @media (min-width: ${$props => $props.css ? '501px' : '601px'}) and (max-width: 900px) {
       font-size: ${$props => $props.css ? '15px' : '30px'};
    }


    @media (min-width: ${$props => $props.css && '901px'}) and (max-width: ${$props => $props.css && ' 1140px'}) {
        font-size: ${$props => $props.css && ' 20px'}
    }
`;

export default function TitleMob ({title, css, action, mob}) {

    return <Title css={css} mod={mob} action={action}><MarkedToHTML>{title}</MarkedToHTML></Title>
}