import styled from 'styled-components';
import CardOneMob from './CardOneMob';
import IntroTitle from '../titles/IntroMobilisation';

// ----- CSS SECTION MOBILISATION ----- // 

// const Article = styled.article`
//     display: ${$props => $props.show ? "none" : "block"};   
// `;

const SectionAllCard = styled.section`
    width: 90%;
    margin: 0 auto;
    border-top: solid 1px black;
    padding-top: 8vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: hidden;

    @media (max-width: 500px) {
        flex-direction: column;
    }
     
`;

 export default function CardMobilisation ({content, onClick, show, saveItem}) {

    return (
        <article>
        <IntroTitle />
        <SectionAllCard >
            {content.map((mob,index) => <CardOneMob mob={mob} key={'mobilisation - ' + index} onClick={onClick} saveItem={saveItem} /> )}
        </SectionAllCard>
        </article>
    )
}
