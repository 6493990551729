import { Link } from 'react-scroll';
import styled from "styled-components";


const AccesContact = styled(Link)`
     text-decoration: none;
    padding: 1rem 2rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    cursor: pointer;
    color: black;
    text-transform: uppercase;
    font-size: 15px;
    transition: all 0.3s ease-in;
 
    &:hover {
      color:#f83030a1;
    }
  `;

export default function BoutonContact({onClose}) {

    return (
        <>
            <AccesContact onClick={onClose} activeClass="active" to="contact" spy={true} smooth={true} duration={1000} >Contact</AccesContact>
        </>


    )
}