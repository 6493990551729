import styled from "styled-components"

const Separator = styled.div`
    width: 2px;
    color: transparent;
    background-color: #FDFBFB;
    @media (max-width: 778px) {
        display:none;
    }
    `;

export default function VerticalSeparator(){
    return(
        <Separator>.
        </Separator>
    )
}