import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    margin: 20vh;
    text-align: center;
`;


export default function NotFound () {
    let location = useLocation();

    return (
        <Container>
            <h1>Not match for url requested</h1>
            <p>Sorry buddy, no match for <code>{location.pathname}</code></p>
        </Container>
    )
}