import styled from "styled-components";
import TitleMob from "./TitleMob";
import SubTitle from "./SubTitle";

const DivTitle = styled.div`
    width: ${$props => $props.display && "40%"};
    min-height: ${$props => $props.display && "70vh"};
    margin-left: ${$props => $props.display && "5vw"};
    position: ${$props => $props.display && "relative"};

    @media (max-width: ${$props => $props.display && "500px"}) {
        width: ${$props => $props.display && "90vw"};
        height: ${$props => $props.display && "40vh"};
        min-height: ${$props => $props.display && "0"};
    }

    @media (min-width: ${$props => $props.mob && "501px"}) and (max-width: ${$props => $props.mob && "750px"}) {
        width: ${$props => $props.mob && "85%"};
        min-height: ${$props => $props.display && "40vh"};
    }
    
`;

const RedLine = styled.div`
    width: ${$props => $props.css ? '10vw' : '25vw'};
    height: 1vh;
    background-color: #D12834;;
    margin: ${$props => $props.css ? '2vh 0 2vh 3vh' : '2vh 0 3vh 0'};
    float: ${$props => !$props.css && 'right'};
    align-self: ${$props => !$props.css && 'right'};

    @media (max-width: ${$props => $props.css ? '500px' : '600px'}) {
       width: ${$props => $props.css ? '50vw' : '40vw'}
    }

    @media (min-width: ${$props => $props.css && '501px'}) and (max-width: ${$props => $props.css && '900px'}) {
        width: ${$props => $props.css && '18vw'};
        height: ${$props => $props.css && '0.6vh'};
    }
`;

const Absolute = styled.div`
    position: ${$props => $props.display ? "absolute" : $props.css ? "static" : null}; 
    bottom: ${$props => $props.display ? "0" : "none"};
    right: ${$props => $props.display ? "0" : "none"};
`;

const Img = styled.img`
    height: ${$props => $props.action ? '20vh' : '15vh'};
    width: ${$props => $props.action ? '34.9vw' : '24.9vw'};
    object-fit: cover;
    object-position: center;

    @media (max-width: 500px) {
        width: 79.8vw;
    }

    @media (min-width: 501px) and (max-width: 900px) {
        width: 40vw;
    }
`;

export default function AllTitle (props) {

    return (
        <DivTitle display={props.section} mob={props.mob}>
            <Absolute display={props.section} css={props.card}>
                { props.picture ? <Img src={process.env.REACT_APP_API_URL+props.picture} alt={props.title} action={props.action} /> : null }
                <TitleMob css={props.card} title={props.title} action={props.action} />
                <RedLine css={props.card}></RedLine>
                { props.subTitle ? <SubTitle css={props.card} subTitle={props.subTitle} mob={props.mob} /> : null }
            </Absolute>
        </DivTitle>
    )
}