import Title from "../../../Components/Title";
import styled from "styled-components";
import Carousel from "./Carousel";
import useFetchWithCache from "../../../Hooks/UseFetchWithCach";

const SectionNews = styled.section`
    padding:  10vh 0;
    background: rgba(239, 236, 236, 0.55)
    `;

const Red = styled.u`
    text-decoration: none;
    color: #D12834`;

const TitleNews = styled(Title)`
    @media (max-width: 778px) {
        text-align: left;
        padding-left: 5vh;
    }
    `;

const ExternalLink = styled.a`
    text-decoration: none;
    color:black`;

export default function News(){
    const content = useFetchWithCache("/news");
    console.log(content);
        
    if(content.length === 0) {
        return (
            <SectionNews>
                <ExternalLink href="https://www.linkedin.com/company/club-top-20/" target="_blank" rel="noreferrer">
                    <TitleNews h='h1' align='center' size='5.5vmin'>Suivez <Red>notre actualité</Red></TitleNews>
                </ExternalLink>
            </SectionNews>
        )
        
    }
    return (

        <SectionNews>
            <TitleNews h='h1' align='center' size='8vmin'><Red>{content.length > 1 ? 'Nos' : 'Notre'}</Red> {content.length > 1 ? 'actualités' : 'actualité'}</TitleNews>
            <Carousel data={content} />
        </SectionNews>
    )
}