import styled from "styled-components";

//--------- CSS IMG MODAL MOBILISATION --------//

const Img = styled.img`
    width: 100%;
    height: 25vh;
    object-fit: cover;
    object-position: center;

    @media (max-width: 500px) {
       width: 90vw;
       margin-left: 2vw;
    }

    @media (min-width: 501px) and (max-width: 750px) {
        width: 80vw;
    }
`;

export default function ImgMob ({picture, alt}) {

    return <Img src={picture && process.env.REACT_APP_API_URL+picture.url} alt={alt}/>  
} 