import { useState , useEffect, useCallback } from "react";


/*----- Generic function to make an Ajax request with a loading system 
        if the data takes time to be displayed ----*/
        
export default function useFetch(url) {

    const [state, setState] = useState({
        items: [],
        loading: true
    })

    const getItems = useCallback(async function(){
        let response = await fetch(url);
        const data = await response.json();
        setState({
            items: data,
            loading: false
        });
    },[url]);

    useEffect(() => {
        getItems();
    }, [getItems]);

    return [
        state.loading,
        state.items
    ]
}