import styled from 'styled-components';
import CitationImg from '../../../images/new_membres.jpg';
import CitationMembers from './citation_member';
import LinkMembers from './linkMembers';

const Img = styled.img`
    filter: brightness(1) contrast(100%) saturate(70%) grayscale(100%);
    object-fit: contain;
    height:100%;
    max-width: 800px;
    
    @media (max-width: 450px) {
        margin-top: 10%;
        margin-left:5%;
        margin-right:5%;
    }

    @media (min-width: 451px) and (max-width: 530px) {
       
        margin-top: 10%;
        margin-left:5%;
        margin-right:5%;
    }

    @media (min-width: 531px) and (max-width: 660px) {
        margin-top: 4%;
        margin-left:5%;
        margin-right:5%;
    }

    @media (min-width: 660px) and (max-width: 720px) {
        margin-top: 4%;
        margin-left:2%;
        margin-right:2%;
    }

    @media (min-width: 721px) and (max-width: 1000px) {
        margin-top: 10%;
        margin-left:5%;
        margin-right:5%;
    }
    @media (min-width: 1001px) and (max-width: 1600px) {
        margin-top: 10%;
        margin-left:5%;
        margin-right:5%;
    }
`;

const ImgDiv = styled.div`
    height: 30em;
    margin: 10vh 0 0 2vw;
    display: flex;
    flex: 1;

    @media (max-width: 450px) {
        height: 13em;
        justify-content:center;
        margin:0;
        margin-top: 10%;
        width:100%;

    }

    @media (min-width: 451px) and (max-width: 530px) {
        height: 16em;
        justify-content:center;
        margin:0;
        margin-top: 10%;
        width:100%;
    }

    @media (min-width: 531px) and (max-width: 660px) {
        height: 20em;
        justify-content: center;
        margin:0;
        margin-top: 4%;
        width:100%;
    }

    @media (min-width: 660px) and (max-width: 720px) {
        height: 22em;
        justify-content:center;
        margin:0;
        margin-top: 4%;
        width:100%;
        display: flex;

    }

    @media (min-width: 721px) and (max-width: 1155px) {
        display: flex;
        height: 24em;
        justify-content: center;
        margin:0;
        margin-top: 10%;
        width:100%;
    }

  
`;

const DivRow = styled.div`
    flex: 1;
    // voir taille width pour bien place elements
    display: flex;
    flex-direction: row;
    margin:0;
    margin-bottom: 10%;
    padding: 0;
    align-items: center;
    justify-content: center;

   
    @media (min-width: 721px) and (max-width: 1155px) {
        width:100%;
        align-items: center;
        justify-content: center;
        margin-top:10%;
    }
    @media (min-width: 660px) and (max-width: 720px) {
        width:100%;
        align-items: center;
        justify-content: center;
        margin:0;
        margin-top:20%;
        margin-bottom: 10%;
    }
    @media (max-width: 659px) {
        margin:0;
        margin-top:13%;
        width:100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 10%;

    }
    @media (max-width: 460px) {
        margin:0;
        margin-top:25%;
        width:100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 10%;
    }
`;

const SectionQuotes = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin:0;
    padding:0;
    @media (max-width: 1600px) {
        
    }
    @media (max-width: 460px) {
        margin-top:8%;
        width:100%;
        align-items: center;
        justify-content: center;
    }
`;

export default function citation({content}){

    return (
        <SectionQuotes>
            <ImgDiv>
                <Img src={CitationImg} alt="membres" />
            </ImgDiv>
            <DivRow>
                <CitationMembers quote={content.presidentQuote}/>
                <LinkMembers text={content.membersText}/>
            </DivRow> 
        </SectionQuotes>
    )
}