import marked from 'marked';



function MarkedToHTML({children}) {
    const html = children ? marked(children) : '';
    return (
        <span className='marked' dangerouslySetInnerHTML={{__html: html}} ></span>
    );
}

export default MarkedToHTML;