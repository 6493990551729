import { useEffect,  useState } from "react";

const useFetchWithCache = (url, defaultData = []) => {
    const [content,setContent] = useState(defaultData);

    useEffect(() => {
        if(sessionStorage.getItem(url)){
            setContent(JSON.parse(sessionStorage.getItem(url)));
        } else {
            fetch(process.env.REACT_APP_API_URL + url)
            .then(res => res.json())
            .then(data => {
                setContent(data)
                sessionStorage.setItem(url,JSON.stringify(data));
            })
            .catch(err => console.log('Fetch error',err));}
        }
    ,[url])

    return content;
};

export default useFetchWithCache;