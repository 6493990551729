import styled from 'styled-components';
import MarkedToHTML from '../../../Components/MarkedToHTML';

const DivQuote = styled.div`
    position: relative;
    height: 66vh;
    border-right: solid 3px #D12834;
    margin-top: 19vh;
    margin-left: 5vw;

    @media (max-width: 450px) {
        margin-top: 0;
        margin-bottom: 2vh;
        margin-left: 0;
        height: 46vh;
    }

    @media (min-width: 451px) and (max-width: 530px) {
        margin-top: 0;
        margin-bottom: 5vh;
        height: 55vh;
    }

    @media (min-width: 531px) and (max-width: 620px) {
        margin-top: 0;
        margin-bottom: 5vh;
        height: 55vh;
    }

    @media (min-width: 621px) and (max-width: 720px) {
        margin-top: 0;
        margin-bottom: 5vh;
        height: 55vh;
    }

    @media (min-width: 721px) and (max-width: 1000px) {
        margin-top: 8vh;
        margin-bottom: 5vh;
    }

    @media (min-width: 1001px) and (max-width: 1210px) {
        margin-left: 11vw;
    }
`

const P = styled.p`
    padding-right: 1rem;
`;

const DivQuoteText = styled.div`
    font-family: 'Archivo', sans-serif;
    text-transform: uppercase;
    font-size: 3vh;
    text-align: right;
    

    @media (max-width: 450px) {
        font-size: 1.7vh; 
    }

    @media (min-width: 451px) and (max-width: 530px) {
        font-size: 1.7vh;
    }

    @media (min-width: 531px) and (max-width: 620px) {
        font-size: 1.7vh;
    }

    @media (min-width: 621px) and (max-width: 720px) {
        font-size: 2vh;
    }

    @media (min-width: 1001px) and (max-width: 1210px) {
        font-size: 2.3vh;
    }

`

const DivPresident = styled.div`
    font-family: 'Roboto', sans-serif;
    text-align: right;
    padding-right: 1rem;
    text-transform: uppercase;
    font-size: 1.7vh;
    line-height: 1rem;
    margin-top: 4.5rem;
    @media (max-width: 450px) {
        font-size: 1.3vh;
    }

    @media (min-width: 451px) and (max-width: 530px) {
        font-size: 1.3vh;
    }

    @media (min-width: 531px) and (max-width: 620px) {
        font-size: 1.8vh;
    }

    @media (min-width: 621px) and (max-width: 720px) {
        font-size: 2vh;
    }

    @media (min-width: 1001px) and (max-width: 1210px) {
        font-size: 2vh;

    }

`

const Marks = styled.span`
    font-size: 5vh;
    font-family: 'Archivo', sans-serif;

    @media (max-width: 450px) {
        font-size: 3vh;
    }
`

const RedText = styled.span`
    color: #D12834;
    font-weight: bold;
`


export default function citation({quote}){

    return (
        <DivQuote>
            <DivQuoteText>
                <P>
                    <Marks>"</Marks>&emsp;
                    <MarkedToHTML>{quote}</MarkedToHTML><br />
                    <Marks>"</Marks>
                </P>
           </DivQuoteText>

           <DivPresident>
                <p><b>Pierre-Antoine VILLANOVA</b></p>
                <p>Président du club <RedText><b>top 20</b></RedText></p>
                <p>Directeur Général de Corsica linea</p>
           </DivPresident>
        </DivQuote> 
    
    )
}