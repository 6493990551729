import styled from "styled-components";
import CardAction from "../../../Thematique/components/cards/CardAction";


//--------- CSS PAGE DETAILS MOBILISATION --------//

const AllAction = styled.section`
    width: 100%;
    margin-top: 5vh;
    padding-top: 8vh;
    background-color: rgba(241, 238, 238, 0.699);

    @media (max-width: 550px) {
        margin-top: 1vh;
    }
     
`;



export default function SectionAction ({itemAction}) {
    
    return (
        <>
            <AllAction id="allAction">
                <CardAction itemAction={itemAction} /> 
            </AllAction>
        </>
    )
}