// CARD ACTION
import styled from 'styled-components';
import AllTitles from '../titles/AllTitles'
import { Link } from 'react-router-dom';

// ----- CSS CARD MOBILISATION & ACTION ----- // 

const WhiteLine = styled.div`
    width: 3vw;
    height: 0.5vh;
    background-color: white;
    margin: 0 auto;
    margin-top: 1vh;

    @media (max-width: 500px) {
        width: 10vw;
        height: 0.3vh;
    }

    @media (min-width: 501px) and (max-width: 900px) {
        width: 8vw;
        height: 0.3vh;
    }

    @media (min-width: 901px) and (max-width: 1140px) {
        width: 5vw;
        height: 0.3vh;
    }
`;

const CardOverlay = styled(Link)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: none;
    transition: .5s ease;
    background-color: #110f0fd8;

    &:hover ${WhiteLine} {
        width: 4vw;
    }

    @media (max-width: 500px) {
        &:hover ${WhiteLine} {
            width: 25vw;
        }
    }

    @media (min-width: 501px) and (max-width: 900px) {
        &:hover ${WhiteLine} {
            width: 15vw;
        }
    }

    @media (min-width: 901px) and (max-width: 1140px) {
        &:hover ${WhiteLine} {
            width: 10vw;
        }
    }
`;

const DivOneCard = styled.div` 
    width: ${$props => $props.action ? '35vw' : '25vw'};
    height: ${$props => $props.action ? '55vh' : '75vh'};
    border: solid 1px #9B9B9B;
    margin-bottom: 8vh;
    text-decoration: none;
    color: black;
    transition: all .5s ease-in;
    position: relative;
    background-color: white;

    &:hover ${CardOverlay} {
        opacity: 1;
    }

    @media (max-width: 500px) {
        width: 80vw;
        margin: 0 auto;
        margin-bottom: 5vh;
        height: 60vh;

        &:hover ${CardOverlay} {
            opacity: 1;
        }

    }

    @media (min-width: 501px) and (max-width: 900px) {
        width: 40vw;
        margin: 0 auto;
        margin-bottom: 5vh;

        &:hover ${CardOverlay} {
            opacity: 1;
        }

    }
 
`;

const TextOverlay = styled.div`
    color: white;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

`;


const TextDisco = styled.p`
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    padding-bottom: 1vh;
    width: 50%;
    margin: 0 auto;
    color: white;
`;


export default function CardAction (props) {

    return (
        <DivOneCard id="card" key={ props.index ? props.index : null} action={props.action}>
            <AllTitles title={props.title} picture={ props.picture && props.picture.url} card={props.card} action={props.action} subTitle={props.subTitle} />
            { props.action ? 
                <CardOverlay id={props.id} to={'/action/title='+props.title+'&id='+props.id}>
                    <TextOverlay>
                        <div>
                            <TextDisco>Lire l'article</TextDisco>
                            <WhiteLine></WhiteLine>
                        </div>
                    </TextOverlay>
                </CardOverlay> : 

                <CardOverlay id={props.id} to={'/thematique/'+props.id} >
                    <TextOverlay>
                        <div>
                            <TextDisco>Découvrir</TextDisco>
                            <WhiteLine></WhiteLine>
                        </div>
                    </TextOverlay>
                </CardOverlay>
            }


        </DivOneCard>
    )
}


