import styled from "styled-components";

const WrapperFlex = styled.div`
    display: flex;
    flex-wrap: ${props => props.noWrap ? 'nowrap' : 'wrap'};
    justify-content:${props => props.position ? props.position : "center"};
    align-content:${props => props.position ? props.position : "center"};
    align-items:${props => props.position ? props.position : "center"};
    justify-items: ${props => props.position ? props.position : "center"};
    flex-direction: ${props => (props.direction ? props.direction : "row")};

    margin-top: ${props => props.mt ? props.mt : "0"};
    margin-right: ${props => props.mr ? props.mr : "0"};
    margin-bottom: ${props => props.mb ? props.mb : "0"};
    margin-left: ${props => props.ml ? props.ml : "0"};

    margin: ${props => props.margin ? props.margin : null};

    max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
    `;

export default WrapperFlex;