import styled from "styled-components";
import { useForm} from "react-hook-form";
import CaptchaTest from './simple-captcha'; 
import { onSubmit }  from "./traitementForm";


const ContactForm = () => {

const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

    return (
        <Form name="form" onSubmit={handleSubmit(onSubmit)} className="form">
            <H1><Hightlight>Contact</Hightlight>ez-nous</H1>
            <Row>
                <Success id="success">Email bien envoyé !</Success>
                <ErrorServer id="ErrorServer" ></ErrorServer>
            </Row>    
            <Row>
                <Column>
                    <Input {...register("prenom", { 
                            required: "This field is required" })}
                        small 
                        type="text" 
                        placeholder="Votre prénom"/>
                    {errors.prenom && <Error>{ errors.prenom.message }</Error>}
                </Column>
                <Column>
                    <Input {...register("nom", { 
                            required: "This field is required" })} 
                        small 
                        type="text" 
                        placeholder="Votre nom"/>    
                    {errors.nom && <Error>{ errors.nom.message }</Error>}
                </Column>
            </Row>
            <Column>
                <Input {...register("email", { 
                            required: "This field is required",
                            pattern: {
                                value : /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                message : "email incorrect"
                            }})}  
                        placeholder="Votre e-mail" />
                {errors.email && <Error>{ errors.email.message }</Error>}
            </Column>
            <Column>
                <Input {...register("company", { 
                            required: "This field is required" })} 
                        type="company" 
                        placeholder="Votre entreprise"/>
                {errors.company && <Error>{ errors.company.message }</Error>}
            </Column>
            <Column>
                <Label>Votre message</Label>
                <Textarea {...register("message", { 
                            required: "This field is required" })} 
                        name="message"></Textarea>
                {errors.message && <Error>{ errors.message.message }</Error>}

            </Column>
            <Column>
                <Row>     
                    <CaptchaTest />
                </Row> 
                <ErrorCaptcha id="errorCaptcha">Captcha invalide !</ErrorCaptcha>
            </Column>
            <Button disabled={isSubmitting}>Envoyer</Button> 
        </Form>
    )
}
const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 375px;
    flex-direction: row;
    padding: 0 5vh 0 5vh;
    @media (min-width: 778px) {
        width: 58%;
        padding: 0 100px 0 100px;
    }`;

const H1 = styled.h1`
    width: 100%;
    font-size: 2rem;
    text-transform: uppercase;
    margign-left:-15px;
    color: #FDFBFB;`;

const Hightlight = styled.span`
    padding-left:15px;
    background-color: rgba(106, 4, 15, 0.5)`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction:row;
    justify-content: space-around;
`;

const Column = styled.div`
    width: 100%;
    display: flex;
    justify-content:space-between;
    flex-direction:column;
    padding: 10px;
`;
const Error = styled.div`
    border-radius:.2em;
    padding:8px;
    margin:7px;
    color: #D8000C;
	background-color: #FFBABA;
    font-size:11px;
`;
const Success = styled.div`
    display:none;
    border-radius:.2em;
    padding:7px;
    margin:4px;
    color: #4F8A10;
    background-color: #DFF2BF;
    font-size:11px;
`;
const ErrorServer = styled.div`
    display:none;
    border-radius:.2em;
    padding:7px;
    margin:4px;
    color: #D8000C;
    background-color: #FFBABA;
    font-size:11px;
`;

const ErrorCaptcha = styled.div`
    display:none;
    border-radius:.2em;
    padding:7px;
    margin:4px;
    color: #D8000C;
    background-color: #FFBABA;
    font-size:11px;
`;

const Input = styled.input`
    background-color: transparent;
    border: none;
    width: 100%;
    border-bottom: 1px solid white;
    padding: 20px 20px 10px 0;
    &::placeholder { 
        color: #FDFBFB; /* Kind of white */
        opacity: 1; /* Firefox */
        font-size: 0.8rem;
    }`;

const Label = styled.label`
    color: #FDFBFB; /* Kind of white */
    opacity: 1; /* Firefox */
    margin-top:20px;
    font-size: 0.8rem;
`;

const Textarea = styled.textarea`
    width: 100%;
    background-color: transparent;
    border: none;
    border: 1px solid white;
    padding: 20px;
    margin-top:10px;
    &::placeholder { 
        color: #FDFBFB; /* Kind of white */
        opacity: 1; /* Firefox */
    }`;

const Button = styled.button`
    margin: 20px auto 20px auto;
    background-color: transparent;
    border: none;
    border-top: 2px solid  rgba(106, 4, 15, 0.5) ;
    color: #FDFBFB;
    font-weight: bold;
    text-transform: uppercase;
    `;

export default ContactForm ;