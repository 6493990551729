import styled from "styled-components";
import ImgMob from "../../../Thematique/components/titles/ImgMob";
import Markdown from "../../../../Components/MarkedToHTML";
import { Link } from 'react-router-dom';

//--------- CSS DESCRIPTION--------//

const Des = styled.div`
    position: absolute;
    bottom: 0;

    @media (max-width: 500px) {
       width: 80%;
       position: static;
       bottom: none;
       margin-left: 2vw;
    }

`;

const DivDescription = styled.div`
    position: relative;
    width: 45%;
    min-height: 70vh;
    margin-left: 3vw;

    @media (max-width: 500px) {
       width: 90%;
       margin: 0;
       margin-bottom: 2vh;
       height: 60vh;
    }

    @media (min-width: 501px) and (max-width: 750px) {
       width: 90%;
       min-height: 60vh;
    }
`;

const TextDescription = styled.p`
    width: 100%;
    font-family: Montserrat, sans-serif;
    padding-top: 2vh;
    font-size: 1vw;
    text-align: justify;

    @media (max-width: 500px) {
       width: 90%;
       text-align: left;
       margin-left: 2vw;
       font-size: 3vw;
       line-height: 3vh;
    }

    @media (min-width: 501px) and (max-width: 750px) {
        width: 90%;
        text-align: left;
        margin-left: 2vw;
        font-size: 1.8vw;
        line-height: 3vh;   
    }

    @media (min-width: 2000px) {
       font-size: 0.7vw;
    }

`;

const ReturnButton = styled(Link)`
    margin-top: 10vh;
    float: right;
    padding-bottom: 7vh;
    text-decoration: none;
    color: black;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1vw;
    transition: all 0.3s ease-in;

    &:hover {
        color: #f83030a1;
    }

    @media (max-width: 500px) {
        margin-top: 5vh;
        float: left;
        padding: 0 2vw 3vh 2vw;
        font-size: 2.5vw;
        
    }

    @media (min-width: 501px) and (max-width: 750px) {
        margin-top: 5vh;
        float: left;
        padding: 0 2vw 3vh 2vw;
        font-size: 1.8vw;
        
    }

    @media (min-width: 2000px) {
       font-size: 0.7vw;
    }
`;

export default function Description (props) {
    
    return (
        <DivDescription>
            <Des>
                <ReturnButton to={'/thematiques'}><i className="fas fa-chevron-left"></i> Retour à nos actions</ReturnButton>
                <ImgMob picture={props.image} alt={props.title} />  
                <TextDescription><Markdown>{props.description}</Markdown></TextDescription>
            </Des>
        </DivDescription>
    )
}